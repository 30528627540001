import './components.scss';
import components from './components';


// @ts-ignore
const componentNodes = document.querySelectorAll(['[data-component]']);

if (componentNodes) {
  componentNodes.forEach((el) => {
    const componentName = el.getAttribute('data-component');
    if (componentName) {
      components[componentName]().then((component) => {
        new component.default(el);
      });
    }
  });
}
